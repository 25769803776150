import React, { useState } from 'react'
import { Button, IconButton, InputAdornment, Input, Link } from '@mui/material'
import { goToHomePage, hideLoader, showLoader } from '../../helpers/Utils'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import '../../assets/styleSheets/viewRequest.scss'
import { CDN_URL } from '../../constants/siteConstant'
import Copyright from '../common/Copyright'

const MUIInput = styled(Input)({
  '&&&': {
    display: 'flex',
    backgroundColor: 'white',
    border: '2px solid #EBEAED',
    padding: '12px 0px 12px 24px',
    borderRadius: '100px',
    fontFamily: 'DMSans',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '26px',
    width: '330px'
  }
})

const ErrorSpan = styled('span')({
  marginTop: '4px',
  color: 'red',
  fontFamily: 'DMSans',
  fontSize: '12px'
})

export default function Login (props) {
  const { authActions } = props
  const [showPassword, setShowPassword] = useState(false)
  const [loginData, setLoginData] = useState({
    email: '',
    password: ''
  })
  const { t } = useTranslation(['auth'])

  const [validationErrors, setValidationErrors] = useState({
    email: '',
    password: ''
  })

  const handleChange = (event) => {
    setLoginData({ ...loginData, [event.target.name]: event.target.value })
    setValidationErrors({ ...validationErrors, [event.target.name]: '' })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    showLoader()
    const errors = {}
    if (!loginData.email.trim()) {
      errors.email = t('emailRequired')
    }
    if (!loginData.password.trim()) {
      errors.password = t('passwordRequired')
    }
    if (Object.keys(errors).length === 0) {
      authActions.tryLoginWithEmail(loginData)
      hideLoader()
    } else {
      hideLoader()
      setValidationErrors(errors)
    }
  }

  function handleKeyPress (event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSubmit()
    }
  }

  const togglePassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className='view-request-container'>
      <section className='step-1-wrapper'>
        <div className='left-container'>
          <div
            className='h-full bg-cover vertical-center flex-d-column bg-image' style={{
              backgroundImage: `url(${CDN_URL + '/landing-page/helloparts_hero_full.png'
                })`
            }}
          >
            <div className='left-wrapper'>
              <div
                className='d-flex items-center flex-d-column title-cont welcome-text-wrapper'
              >
                <span className='font-Manrope gp-text28 gp-pt40 font-weight-700 d-flex text-white leading-52 -tracking-04'>
                  {t('welcomeToHelloParts')}
                </span>
                <span className='font-Manrope gp-text20 font-weight-400 leading-26 text-white gp-pt4'>
                  {t('partsMadeEasy')}
                </span>
              </div>
              <div
                className='d-flex items-center flex-d-column title-cont lead-container'
              >
                <span className='font-Inconsolata font-weight-700 d-flex text-white leading-52 -tracking-04'>
                  {t('pageTitle')}
                </span>
                <span className='font-Manrope gp-text16 font-weight-400 leading-26 text-white gp-pt16 page-subtitle'>
                  {t('pageSubtitle')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='right-container'>
          <div
            className='d-flex  flex-d-column rounded-10 form-cont'
          >
            <div className='logo-container gp-mt12'>
              <img src='https://d3brnpc5nhvc9v.cloudfront.net/static-assets-helloparts/logos/helloparts-Logo-x.png' alt='helloparts' height='40px' className='cp logo-style' onClick={goToHomePage} />
            </div>
            <div
              className='d-flex flex-d-column input-cont'
            >
              <span className='font-DMSans gp-text24 font-weight-500 leading-26 gp-mt20' style={{ color: '#9C9AA5' }}>{t('signIn')}</span>
              <section className='input-wrapper'>
                <div className='d-flex flex-d-column'>
                  <label className='gp-mb8 common-label required font-Manrope'>{t('email')}</label>
                  <MUIInput
                    name='email'
                    onChange={handleChange}
                    id='email'
                    disableUnderline
                    value={loginData.email}
                    style={{
                      fontSize: '12px'
                    }}
                    placeholder={t('email')}
                  />
                  <ErrorSpan>{validationErrors.email || ''}</ErrorSpan>
                </div>

                <div className='d-flex flex-d-column gp-mt20'>
                  <label className='gp-mb8 common-label required font-Manrope'>{t('password')}</label>
                  <MUIInput
                    name='password'
                    type={showPassword ? 'text' : 'password'}
                    id='password'
                    onChange={handleChange}
                    disableUnderline
                    value={loginData.password}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton aria-label='toggle password visibility' onClick={togglePassword} edge='end'>
                          {showPassword ? <Visibility sx={{ fontSize: '14px' }} /> : <VisibilityOff sx={{ fontSize: '14px' }} />}
                        </IconButton>
                      </InputAdornment>
                    }
                    onKeyPress={handleKeyPress}
                    style={{
                      fontSize: '12px'
                    }}
                    placeholder={t('password')}
                  />
                  <div>
                    <ErrorSpan>{validationErrors.password || ''}</ErrorSpan>
                    <Link href='/forgot-password' underline='hover' className='font-Manrope' sx={{ float: 'right', clear: 'both' }} style={{ color: '#919191', marginTop: '10px', fontSize: '16px', fontWeight: 400 }}>
                      {t('forgotPassword')}
                    </Link>
                  </div>
                </div>
              </section>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                style={{
                  borderRadius: '8px',
                  textTransform: 'none',
                  color: 'white',
                  backgroundColor: '#6926D9',
                  padding: '10px 0px 10px 0px',
                  fontWeight: '500',
                  fontSize: '16px'
                }}
                className='font-Manrope'
                onClick={handleSubmit}
              >
                {t('signIn')}
              </Button>
              <Copyright />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
