import React from 'react'
import { Button, Grid, TextField, IconButton } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import CircularProgress from '@mui/material/CircularProgress'
import '../../assets/styleSheets/viewRequest.scss'
import { goToHomePage } from '../../helpers/Utils'

export default function ViewRequestActiveStep3 (props) {
  const { partDealersList, errors, onStep3CTA, handleDealerSelection, selectedDealers, isLoading, handleInputChange, inputValue, handleAddFields, handleRemoveFields, handleInputChangeForNewPartDealer, onSkipStep3 } = props
  return (
    <section className='step-2-wrapper'>
      <div className='step-2-contents'>
        <div className='w-90'>
          <div className='gp-mt40 tc'>
            <img src='https://d3brnpc5nhvc9v.cloudfront.net/static-assets-helloparts/logos/helloparts-Logo-x.png' alt='helloparts' height='40px' className='cp logo-style' onClick={goToHomePage} />
          </div>
          <div className='gp-mt16 gp-mb12 tc light-grey-color gp-text14'>2/2</div>
          <div className='gp-mt16 black-text gp-text22 tc'>Ihre Teilelieferanten</div>
          <div className='gp-mt16 light-grey-color gp-text16 tc'>Jetzt konfigurieren um direkt zu bestellen</div>
          {isLoading
            ? <div className='progress-loader'><CircularProgress /></div>
            : (
              <div className='dealers-cont gp-mt32'>
                <Grid container spacing={1} className='grid-main-cont'>
                  {partDealersList?.map((dealer) => {
                    const isSelected = selectedDealers.some((selectedDealer) => selectedDealer.part_dealer_id === dealer.id)
                    const error = errors[dealer.id]
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={2}
                        key={dealer.id}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        {dealer?.logo_url
                          ? (
                            <img
                              src={dealer?.logo_url}
                              alt={`Dealer ${dealer.part_dealer_id}`}
                              height='120px'
                              width='120px'
                              className={`cp seller_logo ${isSelected ? 'selected-seller' : 'br_1'}`}
                              onClick={() => handleDealerSelection(dealer.id)}
                            />
                            )
                          : (
                            <div
                              style={{
                                height: '120px',
                                width: '120px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '5px'
                              }}
                              onClick={() => handleDealerSelection(dealer.id)}
                              className={`cp break-word seller_logo ${isSelected ? 'selected-seller' : 'br_1'}`}
                            >
                              {dealer?.name}
                            </div>
                            )}
                        <TextField
                          placeholder='Kunden-Nr.'
                          variant='outlined'
                          size='medium'
                          required
                          value={selectedDealers.find((selectedDealer) => selectedDealer.part_dealer_id === dealer.id)?.part_dealer_customer_number || ''}
                          onChange={(e) => handleInputChange(dealer.id, e.target.value)}
                          fullWidth
                          margin='normal'
                          style={{ width: '120px' }}
                          InputProps={{
                            style: {
                              height: '40px',
                              fontSize: '12px',
                              padding: '0 5px'
                            },
                            inputProps: {
                              type: 'number',
                              min: 0
                            }
                          }}
                          InputLabelProps={{
                            style: {
                              top: '-5px',
                              fontSize: '12px',
                              verticalAlign: 'middle'
                            }
                          }}
                          error={!!error}
                          helperText={error}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
                <p className='tc font-Manrope light-grey-color gp-text24 supplier_not_listed'>Ihr Lieferant ist nicht in der Liste? Weitere Lieferanten hinzufügen</p>
                <div className='flex-center flex-d-column'>

                  <div className='vertical-center head-cont'>
                    <div className='d-flex flex-d-column font-Manrope ml_10'>
                      <div className='font-Manrope'>Teilelieferant</div>
                    </div>
                    <div className='d-flex flex-d-column font-Manrope'>
                      <div>Kundennummer beim Lieferanten</div>
                    </div>
                  </div>

                  {inputValue?.map((inputField, index) => (
                    <div key={index} className='vertical-center gp-pl60'>
                      <div className='d-flex flex-d-column font-Manrope'>
                        <TextField
                          variant='outlined'
                          size='medium'
                          value={inputField.partSupplier}
                          onChange={(e) => handleInputChangeForNewPartDealer(index, e)}
                          fullWidth
                          margin='normal'
                          name='partSupplier'
                          className='font-Manrope plm'
                          placeholder='Max Mustermann Teileservice'
                          style={{ width: '260px', marginRight: '25px' }}
                          InputProps={{
                            style: {
                              height: '40px',
                              fontSize: '12px',
                              padding: '0 5px',
                              fontFamily: 'font-Manrope'
                            }
                          }}
                          InputLabelProps={{
                            style: {
                              top: '-5px',
                              fontSize: '12px',
                              verticalAlign: 'middle'
                            }
                          }}
                        />
                      </div>
                      <div className='d-flex flex-d-column font-Manrope'>
                        <TextField
                          variant='outlined'
                          size='medium'
                          value={inputField.customerNo}
                          onChange={(e) => handleInputChangeForNewPartDealer(index, e)}
                          fullWidth
                          name='customerNo'
                          placeholder='12345'
                          className='font-Manrope'
                          margin='normal'
                          style={{ width: '260px' }}
                          InputProps={{
                            style: {
                              height: '40px',
                              fontSize: '12px',
                              padding: '0 5px'
                            },
                            inputProps: {
                              type: 'number',
                              min: 0
                            }
                          }}
                          InputLabelProps={{
                            style: {
                              top: '-5px',
                              fontSize: '12px',
                              verticalAlign: 'middle'
                            }
                          }}
                        />
                      </div>
                      <div className={`gp-pl20 gp-pt8 ${index > 0 ? 'visible' : 'hide'}`}>
                        <IconButton onClick={() => handleRemoveFields(index)} style={{ color: '#FE686E', marginLeft: '-10px' }}>
                          <DeleteForeverIcon style={{ fontSize: '28px' }} />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                </div>

                <p className='tc gp-text16 light-grey-color tdu gp-mt60 cp' onClick={handleAddFields}>Mehr Teilelieferanten hinzufügen</p>
                <div className='gp-mt20 gp-mb20 tc'>
                  <Button
                    onClick={onStep3CTA}
                    style={{
                      borderRadius: '8px',
                      textTransform: 'none',
                      color: 'white',
                      backgroundColor: '#6926D9',
                      padding: '10px 30px 10px 30px',
                      fontWeight: '500',
                      fontSize: '14px'
                    }}
                  >
                    <span className='font-DMSans gp-text18 font-weight-500 leading-26 text-white'>Weiter</span>
                  </Button>
                </div>
                <p className='tc gp-text16 light-grey-color cursor-hand tdu' onClick={onSkipStep3}>Überspringen und später einrichten</p>
              </div>
              )}
        </div>
      </div>
    </section>
  )
}
