import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import '../../assets/styleSheets/viewRequest.scss'
import { fetchDMSList } from '../../actions/viewRequest'
import { hideLoader, showLoader, showSnackBar } from '../../helpers/Utils'
import { getPDList, trySavingLead, trySigningUp } from '../../services/viewRequest'
import { useTranslation } from 'react-i18next'
import ViewRequestActiveStep1 from './viewRequestActiveStep1'
import ViewRequestActiveStep2 from './viewRequestActiveStep2'
import ViewRequestActiveStep3 from './viewRequestActiveStep3'
import ViewRequestActiveStep4 from './viewRequestActiveStep4'

let queryData

const ViewRequest = () => {
  const [activeStep, setActiveStep] = useState(1)
  const [searchParams] = useSearchParams()
  const [dmsList, setDMSList] = useState([])
  const [partDealersList, setPartsDealers] = useState([])
  const [selectedDealers, setSelectedDealers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const { t } = useTranslation(['viewRequest'])
  const [inputValue, setInputValue] = useState([{ partSupplier: '', customerNo: '' }])

  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    emailError: '',
    passwordError: '',
    dmsId: '',
    dmsIdError: '',
    dmsAccountIdError: '',
    dmsAccountId: '',
    partDealerId: '',
    partDealerCustomerNumber: '',
    partDealerIdError: '',
    partDealerCustomerNumberError: '',
    leadId: '',
    dmsName: '',
    dmsNameError: ''
  })

  useEffect(() => {
    queryData = jwtDecode(searchParams.get('token'))
    setIsLoading(true)
    if (queryData?.email) {
      setFormData({ ...formData, email: queryData.email, workshopAccountName: queryData.workshop_account_name, leadId: queryData.leadId })
    }
    fetchDMSList().then((data) => {
      setDMSList(data.data.data)
    })

    fetchAndUpdatePartDealers()
  }, [])

  const fetchAndUpdatePartDealers = async () => {
    showLoader()
    const partDealersList = await getPDList()
    setPartsDealers(partDealersList)
    if (partDealersList.length > 0) {
      setSelectedDealers([])
      setIsLoading(false)
    }
    hideLoader()
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
      [`${name}Error`]: ''
    })
  }

  const handleInputChangeForNewPartDealer = (index, event) => {
    const values = [...inputValue]
    values[index][event.target.name] = event.target.value
    setInputValue(values)
  }

  const handleAddFields = () => {
    setInputValue([...inputValue, { partSupplier: '', customerNo: '' }])
  }

  const handleRemoveFields = (index) => {
    const values = [...inputValue]
    if (values.length > 1) {
      values.splice(index, 1)
      setInputValue(values)
    }
  }

  const onStep2CTA = async () => {
    if (!formData.dmsId) {
      setFormData({
        ...formData,
        dmsIdError: t('selectDMSID')
      })
      return
    }
    if (formData.dmsId === 9999 && !formData.dmsName) {
      setFormData({
        ...formData,
        dmsNameError: t('enterCustomerNo')
      })
      return
    }
    if (!formData.dmsAccountId) {
      setFormData({
        ...formData,
        dmsAccountIdError: t('selectDMSAccId')
      })
      return
    }
    showLoader()

    const data = {
      dms_id: formData.dmsId,
      dms_account_id: formData.dmsAccountId
    }

    if (formData.dmsId === 9999) {
      data.additional_dms_name = formData.dmsName
    }

    const saveResponse = await trySavingLead(formData.leadId, formData.createdWorkshopId, data)
    if (saveResponse) {
      setActiveStep(activeStep + 1)
    }
    hideLoader()
  }

  const handleDealerSelection = (dealerId) => {
    setSelectedDealers((prevSelectedDealers) => {
      const dealerExists = prevSelectedDealers.find((dealer) => dealer.part_dealer_id === dealerId)

      if (dealerExists) {
        // Deselect dealer and clear customer number
        setInputValue((prevInputValue) =>
          prevInputValue.map((item) =>
            item.part_dealer_id === dealerId ? { ...item, customerNo: '' } : item
          )
        )
        return prevSelectedDealers.filter((dealer) => dealer.part_dealer_id !== dealerId)
      } else {
        return [...prevSelectedDealers, { part_dealer_id: dealerId, part_dealer_customer_number: '' }]
      }
    })
  }

  const handleCustomerNumberChange = (dealerId, customerNumber) => {
    setSelectedDealers((prevSelectedDealers) => {
      if (customerNumber.trim() === '') {
        // Deselect dealer if customer number is empty
        return prevSelectedDealers.filter((dealer) => dealer.part_dealer_id !== dealerId)
      } else {
        // Select dealer and update customer number if not already selected
        const dealerExists = prevSelectedDealers.some((dealer) => dealer.part_dealer_id === dealerId)
        if (dealerExists) {
          return prevSelectedDealers.map((dealer) =>
            dealer.part_dealer_id === dealerId
              ? { ...dealer, part_dealer_customer_number: customerNumber }
              : dealer
          )
        } else {
          return [
            ...prevSelectedDealers,
            { part_dealer_id: dealerId, part_dealer_customer_number: customerNumber }
          ]
        }
      }
    })
  }

  const handleInputChange = (dealerId, value) => {
    handleCustomerNumberChange(dealerId, value)
    if (value) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors }
        delete newErrors[dealerId]
        return newErrors
      })
    }
  }

  const validateAllSelectedDealers = () => {
    const newErrors = {}
    selectedDealers.forEach((dealer) => {
      if (!dealer.part_dealer_customer_number) {
        newErrors[dealer.part_dealer_id] = t('customerNoRequired')
      }
    })
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const onStep3CTA = async () => {
    showLoader()
    if (validateAllSelectedDealers()) {
      const nonEmptyAdditionalPartDealerData = inputValue.filter(field => field.partSupplier.trim() && field.customerNo.trim())

      const payload = {
        part_dealer_request: true,
        first_name: formData.firstName || 'sakshi',
        part_dealer_data: selectedDealers.map((dealer) => ({
          part_dealer_id: dealer.part_dealer_id,
          part_dealer_customer_number: Number(dealer.part_dealer_customer_number)
        })),
        additional_part_dealer_data: nonEmptyAdditionalPartDealerData.map(field => ({
          part_dealer_name: field.partSupplier,
          part_dealer_customer_number: field.customerNo
        }))
      }
      if (payload.part_dealer_data.length > 0 || payload.additional_part_dealer_data.length > 0) {
        const saveResponse = await trySavingLead(formData.leadId, formData.createdWorkshopId, payload)
        if (saveResponse) {
          setActiveStep(activeStep + 1)
        }
      } else {
        showSnackBar({ displayMsg: { error: t('atLeastPartsSupplier') } })
      }
    }
    hideLoader()
  }

  const onSkipStep3 = () => {
    setSelectedDealers([])
    setActiveStep(activeStep + 1)
  }

  const handleSubmit = async () => {
    if (!formData.email) {
      setFormData({
        ...formData,
        emailError: t('emailRequired')
      })
      return
    }
    if (!formData.firstName) {
      setFormData({
        ...formData,
        firstNameError: t('enterName')
      })
      return
    }
    if (!formData.password) {
      setFormData({
        ...formData,
        passwordError: t('enterPassword')
      })
      return
    }
    if (formData.password.length < 8) {
      setFormData({
        ...formData,
        passwordError: t('password8CharLong')
      })
      return
    }
    showLoader()
    const signupResponse = await trySigningUp({
      email: formData?.email,
      password: formData.password,
      services: ['WORKSHOP'],
      first_name: formData.firstName,
      last_name: formData.lastName,
      company_name: formData.workshopAccountName,
      create_workshop: true
    })
    if (signupResponse) {
      setFormData({ ...formData, createdWorkshopId: signupResponse?.workshop?.id, firstName: signupResponse.first_name })
      setActiveStep(activeStep + 1)
    }
    hideLoader()
  }

  return (
    <div className='view-request-container'>
      {activeStep === 1 &&
        <ViewRequestActiveStep1
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />}
      {activeStep === 2 &&
        <ViewRequestActiveStep2
          formData={formData}
          handleChange={handleChange}
          dmsList={dmsList}
          onStep2CTA={onStep2CTA}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />}
      {activeStep === 3 &&
        <ViewRequestActiveStep3
          formData={formData}
          handleChange={handleChange}
          partDealersList={partDealersList}
          onStep3CTA={onStep3CTA}
          handleDealerSelection={handleDealerSelection}
          handleCustomerNumberChange={handleCustomerNumberChange}
          selectedDealers={selectedDealers}
          isLoading={isLoading}
          errors={errors}
          handleInputChange={handleInputChange}
          inputValue={inputValue}
          setInputValue={setInputValue}
          handleInputChangeForNewPartDealer={handleInputChangeForNewPartDealer}
          handleAddFields={handleAddFields}
          handleRemoveFields={handleRemoveFields}
          onSkipStep3={onSkipStep3}
        />}
      {activeStep === 4 &&
        <ViewRequestActiveStep4
          onStep2CTA={onStep2CTA}
        />}
    </div>
  )
}

export default ViewRequest
