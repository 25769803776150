import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CDN_URL } from '../../constants/siteConstant'
import { Button, Input } from '@mui/material'
import styled from 'styled-components'
import '../../assets/styleSheets/viewRequest.scss'
import { goToHomePage, hideLoader, showLoader } from '../../helpers/Utils'
import { tryCustomRegistration } from '../../services/viewRequest'
import ReCAPTCHA from 'react-google-recaptcha'
/* eslint-disable */

const MUIInput = styled(Input)({
  '&&&': {
    display: 'flex',
    backgroundColor: 'white',
    border: '2px solid #EBEAED',
    padding: '12px 0px 12px 24px',
    borderRadius: '100px',
    fontFamily: 'DMSans',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '26px',
    width: '330px'
  }
})

const ErrorSpan = styled('span')({
  marginTop: '4px',
  color: 'red',
  fontFamily: 'DMSans',
  fontSize: '12px'
})

const Register = () => {
  const { t } = useTranslation(['viewRequest'])
  const [activeStep, setActiveStep] = useState(1)
  const recaptchaRef = useRef()

  const [formData, setFormData] = useState({
    email: '',
    workshopName: '',
    recaptcha: '',
    workshopNameError: '',
    emailError: ''
  })

  const [debounceTimeout, setDebounceTimeout] = useState(null)

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }
    }
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
      [`${name}Error`]: ''
    })
    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }

    const newTimeout = setTimeout(() => {
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    }, 1000)

    setDebounceTimeout(newTimeout)
  }

  const handleSubmit = async () => {
    let valid = true
    let errors = {
      emailError: '',
      workshopNameError: ''
    }

    if (!formData.email) {
      errors.emailError = 'Bitte E-Mail eingeben'
      valid = false
    }

    if (!formData.workshopName) {
      errors.workshopNameError = 'Dieses Feld ist erforderlich'
      valid = false
    }

    if (!valid) {
      setFormData({
        ...formData,
        ...errors
      })
      return
    }
    showLoader()
    const signupResponse = await tryCustomRegistration({
      workshop_primary_email: formData?.email, workshop_account_name: formData.workshopName, recaptcha: formData.recaptcha
    })
    if (signupResponse) {
      setActiveStep(activeStep + 1)
    }
    hideLoader()
  }

  const onChangeCallback = (token) => {
    setFormData({ ...formData, recaptcha: token })
  }

  return (
    <div className='view-request-container'>
      {activeStep === 1 &&
        <section className='step-1-wrapper'>
          <div className='left-container'>
            <div
              className='h-full bg-cover vertical-center flex-d-column bg-image' style={{
                backgroundImage: `url(${CDN_URL + '/landing-page/helloparts_hero_full.png'
                  })`
              }}
            >
              <div className='left-wrapper'>
                <div
                  className='d-flex items-center flex-d-column title-cont welcome-text-wrapper'
                >
                  <span className='font-Manrope gp-text28 gp-pt40 font-weight-700 d-flex text-white leading-52 -tracking-04'>
                    {t('welcomeToHelloParts')}
                  </span>
                  <span className='font-Manrope gp-text20 font-weight-400 leading-26 text-white gp-pt4'>
                    {t('partsMadeEasy')}
                  </span>
                </div>
                <div
                  className='d-flex items-center flex-d-column title-cont lead-container'
                >
                  <span className='font-Inconsolata font-weight-700 d-flex text-white leading-52 -tracking-04'>
                    {t('pageTitle')}
                  </span>
                  <span className='font-Manrope gp-text16 font-weight-400 leading-26 text-white gp-pt16 page-subtitle'>
                    {t('pageSubtitle')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='right-container'>
            <div
              className='d-flex  flex-d-column rounded-10 form-cont'
            >
              <div className='logo-container gp-mt12'>
                <img src='https://d3brnpc5nhvc9v.cloudfront.net/static-assets-helloparts/logos/helloparts-Logo-x.png' alt='helloparts' height='40px' className='cp logo-style' onClick={goToHomePage} />
              </div>
              <div
                className='d-flex flex-d-column input-cont'
              >
                <span className='font-DMSans gp-text24 font-weight-500 leading-26 gp-mt20' style={{ color: '#9C9AA5' }}>{t('requestInvitation')}</span>
                <section className='input-wrapper'>
                  <div className='d-flex flex-d-column'>
                    <label className='gp-mb8 common-label required'>{t('emailAddress')}</label>
                    <MUIInput
                      name='email'
                      value={formData.email}
                      onChange={handleChange}
                      disableUnderline
                      style={{
                        borderColor: formData.emailError ? 'red' : '#EBEAED',
                        fontSize: '12px'
                      }}
                      placeholder='max@mustermann.de'
                    />
                    <ErrorSpan>{formData.emailError}</ErrorSpan>
                  </div>

                  <div className='d-flex flex-d-column gp-mt20'>
                    <label className='gp-mb8 common-label required'>{t('nameOfTheCompany')}</label>
                    <MUIInput
                      name='workshopName'
                      value={formData.workshopName}
                      onChange={handleChange}
                      disableUnderline
                      style={{
                        borderColor: formData.workshopNameError ? 'red' : '#EBEAED',
                        fontSize: '12px'
                      }}
                      placeholder='Werkstattname'
                    />
                    <ErrorSpan>{formData.workshopNameError}</ErrorSpan>
                  </div>
                  <div className='gp-mt20'>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      onChange={onChangeCallback}
                      ref={recaptchaRef}
                    />
                  </div>
                </section>
                <div className='gp-text14 tnc-para mrt_10'>{t('agreeTermsCondition')}
                  <a className='black-text gp-ml4 tl-mr4' href='https://goparts.tech/privacy-policy' target="_blank" rel="noreferrer">{t('privacyPolicy')}</a> zu.
                </div>
                <Button
                  onClick={handleSubmit}
                  disabled={!formData.recaptcha}
                  style={{
                    borderRadius: '8px',
                    textTransform: 'none',
                    color: 'white',
                    backgroundColor: '#6926D9',
                    padding: '10px 0px 10px 0px',
                    fontWeight: '500',
                    fontSize: '16px'
                  }}
                >
                  <span className='font-DMSans gp-text18 font-weight-500 leading-26 text-white cp'>{t('sending')}</span>
                </Button>
              </div>
            </div>
          </div>
        </section>}
      {activeStep === 2 &&
        <section className='step-2-wrapper'>
          <div className='step-2-contents'>
            <div className='content-wrapper'>
              <div className='logo-container gp-mt40 tc gp-mb20'>
                <img src='https://d3brnpc5nhvc9v.cloudfront.net/static-assets-helloparts/logos/helloparts-Logo-x.png' alt='helloparts' height='40px' className='cp logo-style' onClick={goToHomePage} />
              </div>
              <div className='gp-mt40 tc'>
                <img src={`${CDN_URL}/landing-page/success-bg.svg`} alt='helloparts' />
              </div>
              <div className='black-text gp-text32 tc font-Manrope'>{t('invitationSent')}</div>
              <div className='light-grey-color tc gp-text24 font-Manrope gp-mt4'>{t('checkEmailConfirm')}</div>
            </div>
            <div className='gp-mt24 gp-mb20 tc'>
              <Button
                onClick={goToHomePage}
                style={{
                  borderRadius: '8px',
                  textTransform: 'none',
                  color: 'white',
                  backgroundColor: '#6926D9',
                  padding: '10px 30px 10px 30px',
                  fontWeight: '500',
                  fontSize: '14px'
                }}
              >
                <span className='font-DMSans gp-text18 font-weight-500 leading-26 text-white'>Schließen</span>
              </Button>
            </div>
          </div>
        </section>}
    </div>
  )
}

export default Register
